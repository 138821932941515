<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)
const router = useRouter()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }
})

const destination = computed(() => {
  if (!isMobile && import.meta.env.MODE === 'production') {
    return '/ads'
  }
  let value = '/affordable-healthcare/groupHealth'

  return value
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
    code: ip.lookup?.stateCode,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  source.value = 0
  setTimeout(() => (loading.value = false), 500)
  source.value = 1600
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

analytics.page({
  name: 'My ACA Benefits',
  category: 'LP',
  product: 'aca',
})

const card = ref(null)

const showBackButtonModal = ref(false)
</script>

<template>
  <Layout
    :hide-header="true"
    footer="aca"
    :class="
      [
        'bg-[#f7f7f7]',
        'flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center',
      ].join(' ')
    "
  >
    <span
      v-if="loading"
      class="inset-0 flex items-center justify-center font-dm"
    >
      <Spinner />
    </span>
    <div
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex mx-auto relative',
        'gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div class="md:flex-grow-0">
        <span class="text-4xl text-gray-800 font-bold font-dm">
          Under 64? <br />
          NOT on Medicaid? <br />
          Get your
          <span class="text-red text-5xl"> ${{ output.toFixed(0) }}+</span>
          health insurance subsidy (savings)
        </span>
      </div>
      <div>
        <div ref="card" class="relative">
          <img
            src="../../assets/aca-benefits-treasury-check.jpg"
            class="w-full h-full"
            alt="Allowance Treasury Check"
          />
        </div>
      </div>

      <div
        class="py-6 px-4 -mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:px-6 sm:mx-0 text-darkBlue"
      >
        <Form
          id="myAcaBenefits"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[{ id: 'start' }, { id: 'call' }]"
          product="aca"
        />
      </div>
    </div>
    <FooterPhone4 text="Live Agent" />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["ayb"],
    "robots": "noindex"
  }
}
</route>
